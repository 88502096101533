/** Import Modules **/
import skrollr from './skrollr.min';
import lazysizes from './lazysizes.min';

if (window.matchMedia('(min-width: 991px)').matches) {
    setTimeout(()=>{
        skrollr.init();
    }, 1000)
}
window.addEventListener('resize', ()=>{
    if (window.matchMedia('(min-width: 991px)').matches) {
        setTimeout(()=>{
            skrollr.init();
        }, 1000)
    }
})

import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

/** Run code **/
window.addEventListener("load", () => {
    //console.log('Javascript Initiated');

    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.customMedia = {
        '--small': '(max-width: 480px)',
        '--medium': '(max-width: 900px)',
        '--large': '(max-width: 1400px)',
    };

    const scrollBar = () => {
        var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        var scrolled = (winScroll / height) * 100;
        document.getElementById("myBar").style.width = scrolled + "%";
    }

    const buttonContainerHeight = () => {
        let arrowDownContainer = document.querySelector('.button-down-container');
        let videoBannerHeight = document.querySelector('.video-banner').clientHeight;
        let videoBannerH1Offset = document.querySelector('.video-banner > .container').offsetTop;
        let videoBannerH1Height = document.querySelector('.video-banner h1').clientHeight;
        let height = videoBannerHeight - (videoBannerH1Offset + videoBannerH1Height);
        arrowDownContainer.style.height = height + 'px';
    }

    const smoothScroll = (element) => {
        let menuHeight_compensetion = document.querySelectorAll('header')[0].clientHeight;
        let anchorBlock = document.querySelector(element.getAttribute('href'));
        let headerOffset = menuHeight_compensetion;
        let elementPosition = anchorBlock.offsetTop;
        let offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    const scrollUp = (element) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // When the user scrolls the page, execute scrollBar
    window.addEventListener('scroll', (ev)=>{
        /*scroll bar top*/
        scrollBar();

        let event = ev.target.scrollingElement.scrollTop;
        /* header animation*/
        if (!document.body.classList.contains('scrolled') && parseInt(event) > 50) {
            document.body.classList.add('scrolled');
        }
        if (ev.target.scrollingElement && parseInt(event) === 0) {
            document.body.classList.remove('scrolled');
        }
        /*scroll up*/
        if (parseInt(event) > 100) {
           document.getElementById('scrollUp').style.cssText = "opacity: 1; height: auto;right: -14px;";
        } else {
            document.getElementById('scrollUp').style.cssText = "opacity: 0; height: 0;right: -105px;";
        }
    });

    let section = document.querySelectorAll("[id^='anchor-']");
    let sections = {};
    let i = 0;

    Array.prototype.forEach.call(section, function(e) {
        sections[e.id] = e.offsetTop;
    });

    window.addEventListener('scroll' , () => {
        var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

        for (i in sections) {
            if (sections[i] <= (scrollPosition + document.querySelectorAll('header')[0].clientHeight)) {
                if(document.querySelector('.active') != undefined)
                    document.querySelector('.active').setAttribute('class', ' ');
                document.querySelector('header a[href*=' + i + ']').setAttribute('class', 'active');
            }
        }
        if(sections['anchor-0'] > (scrollPosition + document.querySelectorAll('header')[0].clientHeight)) {
            if(document.querySelector('.active') != undefined)
                document.querySelector('.active').setAttribute('class', ' ');
        }
    })

    setTimeout(buttonContainerHeight, 500);

    window.addEventListener('resize', () => {
        buttonContainerHeight();
    })

    document.querySelector('.button-down').addEventListener('click',(e) => {
        e.preventDefault();
        smoothScroll(e.target.closest('a'));
    })

    /**
     * Smooth scroll to anchor
     */
    document.querySelectorAll('header a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            smoothScroll(e.target);
        });
    });

    /**
     *  Impressum Datenschutz
     */
    let privacyLink = document.querySelectorAll('footer .footer-navigation a[href^="#"]');

    privacyLink.forEach((link, index) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            openBlock(e.target);
        })

        const openBlock = (element) =>{
            let block = document.querySelector(element.getAttribute('href'));
            let menuHeight_compensetion = document.querySelectorAll('header')[0].clientHeight;

            if(!block.classList.contains('d-none')) {
                let anchorBlock = document.querySelector('footer');
                let elementPosition = anchorBlock.offsetTop;
                let offsetPosition = elementPosition + anchorBlock.clientHeight - document.documentElement.clientHeight;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
                setTimeout(() => {
                    block.classList.add('d-none');
                }, 1000)
            } else {
                block.classList.remove('d-none');
                let anchorBlock = block;
                let headerOffset = menuHeight_compensetion;
                let elementPosition = anchorBlock.offsetTop;
                let offsetPosition = elementPosition - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            }
        }
    })


    /**
    *  Scroll Up Button
    */

    document.getElementById('scrollUp').addEventListener('click', (e) =>{
        e.preventDefault();
        scrollUp(e.target.closest('#scrollUp'));
    })
});